import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import copyIcon from 'src/assets/images/copyIcon.png'
import useFieldStyles from 'src/assets/css/components/ParameterFieldStyles';
import CustomAccordion from 'src/components/CustomAccordion'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { ISetting, SettingEncoding, SettingKind } from 'src/models/IDataPlugin'


const shouldRedact = (setting: ISetting) => setting.sensitive || setting.kind === SettingKind.Password

const DataPluginParameterField: FunctionComponent<any> = (props: PropsWithChildren<any>) => {
    const { fieldId, setting, handleSettingsChange, handleSettingsChangeEvent, error, isCopyEnabled, isLastElement, property } = props
    const name = props.name || setting.name
    const placeholder = ((p: string | undefined) => shouldRedact(setting) && p ? "***" : p)(property || setting.placeholder || setting.value)
    const classes = useFieldStyles();
    const isRequired = !setting.value && !property && !!setting.required?.toString().match(/true/i)

    const settingsChange = (event: any) => {
        handleSettingsChangeEvent ? handleSettingsChangeEvent(event) : null
        handleSettingsChange(event.target.name, event.target.value)
    }

    const jsonParseTopLevelOnly = (json: string) => {
        const parsed = JSON.parse(json)
        Object.entries(parsed).forEach(([k, v]) => {
            if (['string', 'boolean', 'number'].includes(typeof v) && v !== null)
                return

            parsed[k] = JSON.stringify(v)
        })

        return parsed
    }

    const textFieldProps: TextFieldProps = {
        className: 'pointer',
        id: fieldId,
        error: !!error,
        helperText: error?.defaultMessage || (setting.encoding === SettingEncoding.AzureKeyVaultSecret ? "This setting is referencing a secret from the configured Azure key vault" : ''),
        label: setting.label || setting.name,
        onChange: settingsChange,
        variant: 'outlined',
        name: name,
        value: props.currentValue || (isCopyEnabled && (property || setting.value)) || "",
        placeholder: !isCopyEnabled ? placeholder : undefined,
        required: isRequired,
        InputLabelProps: {
            ...(!isRequired && { shrink: true })
        },
        InputProps: isCopyEnabled ? {
            readOnly: true,
            endAdornment:
                <InputAdornment position='end'>
                    <img src={copyIcon} width={20} height={20} />
                </InputAdornment>
        } : {
            readOnly: false,
            endAdornment: null
        },
    }

    let input = <TextField {...textFieldProps} />

    if (shouldRedact(setting)) {
        input = <TextField
            {...textFieldProps}
            type='password' />
    } else if (setting.kind === SettingKind.Email) {
        input = <TextField
            {...textFieldProps}
            type='email' />
    } else if (setting.kind === SettingKind.Integer) {
        input = <TextField
            {...textFieldProps}
            type='number' />
    } else if (setting.kind === SettingKind.Date) {
        const value = (v => v && new Date(v).toISOString().slice(0, -1))(props.currentValue || property || setting.value)

        input = <TextField
            type='datetime-local'
            {...textFieldProps}
            value={value}
            InputLabelProps={{ shrink: true }} />
    } else if (setting.kind === SettingKind.Array) {
            const [elements, _setElements] = useState<string[]>()
            const [newValue, setNewValue] = useState("")
            const [warning, setWarning] = useState<string>()

            const setElements = (newElements: string[] | undefined) => {
                if (JSON.stringify(newElements) !== JSON.stringify(elements))
                    _setElements(newElements)
            }

            useEffect(() => {
                if (props.currentValue)
                    setElements(jsonParseTopLevelOnly(props.currentValue))
            }, [props.currentValue])

            useEffect(() => {
                const parsedElements = elements?.map(e => {
                    try {
                        return JSON.parse(e)
                    } catch {
                        return e
                    }
                })
                handleSettingsChange?.(name, JSON.stringify(parsedElements))
            }, [elements])

            useEffect(() => {
                setWarning(newValue && "Adding a value will override all inherited values")
            }, [newValue])

            const addValue = () => {
                setElements([...elements || [], newValue])
                setNewValue("")
            }

            const updateValue = (index: number, value: string) => {
                const e = [...elements!]
                e[index] = value
                setElements(e)
            }

            const removeValue = (index: number) => {
                const e = [...elements || []]
                e.splice(index, 1)
                setElements(e.length ? e : undefined)
            }

            const autoRemove = (index: number, value: string) => {
                if (value)
                    return

                removeValue(index)
            }

            const overridden = !!elements?.length
            const inherentElements = ((elements: string | undefined) => elements ? jsonParseTopLevelOnly(elements) as Array<string> : [])(property || setting.value)
            const displayElements = overridden ? elements : inherentElements

            input =
                <CustomAccordion title={`${textFieldProps.label} (${displayElements.length || 0})`}>
                    {displayElements.map((e, i) =>
                        <TextField
                            {...textFieldProps}
                            key={i}
                            label={undefined}
                            value={isCopyEnabled ? e : (overridden ? e : "")}
                            placeholder={e}
                            onChange={e => updateValue(i, e.target.value)}
                            onBlur={e => autoRemove(i, e.target.value)}
                            {...(!isCopyEnabled && {
                                InputProps: {
                                    readOnly: !overridden,
                                    endAdornment: !overridden ?
                                        textFieldProps.InputProps?.readOnly :
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => removeValue(i)}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </InputAdornment>
                                }
                            })} />
                    )}
                    {!isCopyEnabled &&
                        <TextField
                            {...textFieldProps}
                            value={newValue}
                            placeholder={undefined}
                            InputLabelProps={undefined}
                            id={`${textFieldProps.id}#add-a-value`}
                            label="Add a value"
                            helperText={!overridden && warning}
                            onChange={e => setNewValue(e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && addValue()}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={addValue}
                                            disabled={!newValue}>
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            FormHelperTextProps={{
                                style: { color: '#f29048' }
                            }} />}
                </CustomAccordion>
    }

    const copyInput = (
        <Grid item xs>
            <CopyToClipboard
                data-testid="copy-plugin-prop"
                text={props.currentValue || (isCopyEnabled && (property || setting.value)) || ""}
                onCopy={props.handleCopyProp}>
                {input}
            </CopyToClipboard>
        </Grid>
    );

    return <div className={classes.root}>{isCopyEnabled ? copyInput : <div style={isLastElement ? {} : { marginBottom: '1.4rem' }}>{input}</div>}</div>;


};

export default DataPluginParameterField;