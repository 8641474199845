
import { Button } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'
import { AxiosError, AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import 'src/assets/css/components/addDataForm.css'
import useStyles from 'src/assets/css/components/FormStyles'
import questionMark from 'src/assets/images/questionMark.png'
import dataIcon from 'src/assets/images/svgs/data.svg'
import CustomAccordion from 'src/components/CustomAccordion'
import EntityName from 'src/components/EntityName'
import DataPluginSettings from 'src/components/DataPluginSettings'
import DataStoreImage from 'src/components/DataStoreImage'
import Spinner from 'src/components/Spinner'
import { useAPIContext } from 'src/contexts/api'
import { useWindowContext } from 'src/contexts/window'
import { useDataPlugin, useDataStore } from 'src/hooks/useCRUDEntity'
import { IStoreFormProps } from 'src/models/components/IStoreForm'
import IDataStore from 'src/models/IDataStore'
import IError from 'src/models/IError'

const StoreForm = (props: IStoreFormProps): JSX.Element => {

    const classes = useStyles()

    const { catalogAPI } = useAPIContext()
    const { size } = useWindowContext()

    const dataStore = useDataStore(catalogAPI, props.dataStore, true)
    const dataPlugin = useDataPlugin(catalogAPI, props.dataPlugin)

    const [error, setError] = useState<IError>()
    const unsetError = () => setError(undefined)

    const [name, setName] = useState(dataStore.name || dataPlugin.label || dataPlugin.name)
    const setInstanceName = (name: string, value: string) => setName(value)

    const [properties, setProperties] = useState(dataStore.properties)
    const [isSaved, setSaved] = useState(false)

    const handleAlertDismissed = () => setSaved(false)

    const handleResponse = async (response: Promise<AxiosResponse<IDataStore>>) => {

        const handleSuccess = () => {
            props.onSaved(dataStore)
            unsetError()
            setSaved(true)
        }

        const handleError = (e: AxiosError<IError>) => setError({
            message: "An unexpected error occurred",
            ...e.response?.data
        })

        response.then(handleSuccess).catch(handleError)
    }

    const handleSubmit = async () => {
        const data: Partial<IDataStore> = {
            name: name,
            properties: properties
        }

        if (dataStore.canCreate) {
            data.dataPlugin = 'loaders/' + dataPlugin.name + '--' + dataPlugin.variant
            await handleResponse(dataStore.create(data))
        } else {
            await handleResponse(dataStore.update(data))
        }
    }

    useEffect(() => {
        if (props.autoValidate)
            handleSubmit()
    }, [])

    return <>
        <div className={!size.smallScreen ? classes.root_medium : classes.root}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSaved}
                autoHideDuration={6000}
                onClose={handleAlertDismissed}>
                <Alert
                    data-testid='saved-datastore'
                    variant='filled'
                    severity='success'>
                    {`'${dataStore.name}' Saved`}
                </Alert>
            </Snackbar>
            {error &&
                <Alert
                    severity='error'>
                    {error.message}
                </Alert>}
            <EntityName
                instanceName={name}
                handleNameChange={setInstanceName}
                error={error?.errors?.find((obj: any) => obj.field === `name` )} />
            <div
                style={{ width: '100%' }}
                className='inline-vertical-center pipeline-row-data'>
                <DataStoreImage
                    size={size}
                    helpButtonClicked={props.helpButtonClicked}
                    helpClass='help-button-img'
                    questionMark={questionMark}
                    dataStoreImg={dataPlugin.logoUrl || dataIcon}
                    dataStoreLabel={dataPlugin.label || dataPlugin.name} />
            </div>
            <div className='properties-wrapper'>
                <CustomAccordion
                    step={1}
                    title='Settings'
                    defaultExpanded>
                    <DataPluginSettings
                        isEditEnabled
                        selectedDataplugin={props.dataPlugin}
                        properties={properties}
                        handleSettingsChange={(name: string, value: any) => setProperties({ ...properties, ...{ [name]: value || undefined } })}
                        handleSettingsUpdate={setProperties}
                        errors={error?.errors} />
                </CustomAccordion>
            </div>
            <form
                onSubmit={handleSubmit}
                id='create-datastore'>
                <div className='form-buttons'>
                    <Button
                        disabled={!dataStore.canCreate && !dataStore.canUpdate}
                        variant='contained'
                        color='primary'
                        onClick={handleSubmit}>
                        <Spinner active={dataStore.isProcessing}>
                            Save
                        </Spinner>
                    </Button>
                    <Button onClick={props.onClose}>
                        Close
                    </Button>
                </div>
            </form>
        </div >
        {!size.smallScreen &&
            <div className='vl-help'></div>}
    </>

}

export default StoreForm
