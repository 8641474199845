import IDataComponent from 'src/models/IDataComponent'
import { PartialExcluding } from 'src/utils/types'

export enum TransformType {
    Default = 'default',
    Custom = 'custom',
    Script = 'script'
}

export interface IDataTransformProps {
    // the pipeline datacomponents
    dataComponents: (PartialExcluding<IDataComponent, 'name'> | IDataComponent)[]

    actions: string[]

    onChangeActions: (actions: string[]) => void

    /*
     * the currently selected transform type (default or custom)
     */
    transformType: TransformType

    /*
     * called when the transform type is changed.
     */
    onChangeTransform: (transformType: TransformType) => void;

    /*
     * the custom script if any
     */
    script?: string

    /*
     * called when the custom script is changed
     */
    handleCustomScriptChange: (code: string) => void;

}